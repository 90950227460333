import * as React from "react"
import Seo from "../../../../components/seo"
import { Link, graphql } from "gatsby"
import Header from "../../../../components/header"
import Footer from "../../../../components/footer"
import Copyright from "../../../../components/copyright"
import { GatsbyImage } from "gatsby-plugin-image"
import { RichText } from 'prismic-reactjs'

const DoubleSidedUhbTapePage = ({data}) => {
  const entry = data.prismicDoubleSidedUhbTapePage
  if (!entry) return null
  return (
    <>
      <Seo title={entry.data.page_title} description={entry.data.meta_description} image="" pathname="/tapes/double/" />
      <Header />
      <div className="w-full pt-6 pb-6 border-b-2 border-grey">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="font-verdana text-md lg:text-lg">
            <Link to="/tapes/" className="border-b-2 border-black">Tapes</Link> 
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg> 
            <Link to="/tapes/double-sided/" className="border-b-2 border-black">Double Sided</Link> 
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 inline" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg> 
            {entry.data.heading.text}
          </div>
        </div>
      </div>
      <div className="w-full pt-12">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="flex-none sm:flex gap-12 justify-between">
            <div className="w-full sm:w-8/12">
              <div className="font-ubuntu font-bold text-red text-4xl sm:text-5xl leading-tight mb-6 sm:mb-12">
                <h1>{entry.data.heading.text}</h1>
              </div>
            </div>
            <div className="w-full sm:w-4/12">
              &nbsp;
            </div>
          </div>
        </div>
      </div>
      <div className="w-full pb-12">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-12">
            {entry.data.tapes.map((entry, i) => (
              <Link to={entry.tape.url} key={`tape_${i}`}>
            <div className="group" >
              
              <div className="relative">
                <div className={`absolute z-10 right-0 px-3 pb-3 center h-full text-white bg-double opacity-1`}>
                  <div className="flex h-full items-end">
                    <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-12 w-12" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
                  </div>
                </div>
                <div className="border-2 border-grey p-6">
                  <GatsbyImage image={entry.image.gatsbyImageData} alt={entry.heading1.text} className="h-36" style={{ width: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} />
                </div>
              </div>
              <div className="font-ubuntu text-black text-2xl leading-tight mt-6 mb-3">
                <h2 className="border-b-2 border-white group-hover:border-b-2 group-hover:border-black inline">{entry.heading1.text}</h2>
              </div>
             
            </div>
            </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="w-full pb-12">
        <div className="w-11/12 mx-auto max-w-screen-2xl">
          <div className="flex-none sm:flex gap-12 items-center justify-between">
            <div className="w-full sm:w-6/12">
              <div className="font-ubuntu prose prose-xl leading-normal">
                {RichText.render(entry.data.text.richText)}
              </div>
              <div className="font-ubuntu text-2xl text-red border-2 border-red py-2 px-4 mt-12 inline-block">
                <Link to="/contact/">Make enquiry <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-5 w-5" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L12.586 11H5a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Copyright />
    </>
  )
}

export const query = graphql`
query {
  prismicDoubleSidedUhbTapePage {
    data {
      page_title
      meta_description
      heading {
        text
      }
      text {
        richText
      }
      tapes {
        heading1 {
          text
        }
        image {
          gatsbyImageData(width: 640, height: 640)
        }
        tape {
          url
        }
      }
    }
  }
}
`

export default DoubleSidedUhbTapePage
